const initBlock = ( $block ) => {
	/**
	 * Initialize the gsap.
	 */
	app.loadGsap( ( gsap ) => {
		const tl = gsap.timeline( {
			scrollTrigger: {
				trigger: $block,
				start: '-=800 top',
				end: '+=1000',
				scrub: 0.5,
			},
		} );
		const $box = $block.find( '.js-section-box' );

		tl.addLabel( 'start' );

		tl
			.from( $box, { scale: 0 } )
			.to( $box, { scale: 1 } );
	} );

	/**
	 * Initialize swiper.
	 */
	app.loadSwiper( ( Swiper, Modules ) => {
		$block.find( '.js-projects' ).each( ( _, projects ) => {
			new Swiper( projects, {
				modules: [Modules.Autoplay],
				spaceBetween: 16,
				centeredSlides: true,
				loop: true,
				speed: 12000,
				autoplay: {
					delay: 0,
					disableOnInteraction: false,
				},
				breakpoints: {
					320: {
						slidesPerView: 1.2,
					},
					768: {
						slidesPerView: 2.5,
					},
					1025: {
						slidesPerView: 3.5,
					},
					1210: {
						slidesPerView: 4,
					},
					1470: {
						slidesPerView: 4.5,
					},
				},
			} );
		} );
	} );
};

app.loadBlock( initBlock, 'projects-slider', '.js-section-projects-slider' );
